//@font-face {
//  font-family: "GoogleSans-Medium";
//  src: local("GoogleSans-Medium"), url(../assets/fonts/GoogleSans-Medium.ttf) format("truetype");
//  font-weight: normal;
//}
//@font-face {
//  font-family: "GoogleSans-Regular";
//  src: local("GoogleSans-Regular"), url(../assets/fonts/GoogleSans-Regular.ttf) format("truetype");
//  font-weight: normal;
//}
//@font-face {
//  font-family: "GoogleSans-Bold";
//  src: local("GoogleSans-Bold"), url(../assets/fonts/GoogleSans-Bold.ttf) format("truetype");
//  font-weight: normal;
//}
:root{
  --fontSize: 1rem;
  --fontFamily: 'GoogleSans';
}
@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-Regular.eot');
  src: url('../assets/fonts/ProductSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-Regular.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-Regular.woff') format('woff'),
  url('../assets/fonts/ProductSans-Regular.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-Regular.svg#ProductSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-MediumItalic.eot');
  src: url('../assets/fonts/ProductSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-MediumItalic.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-MediumItalic.woff') format('woff'),
  url('../assets/fonts/ProductSans-MediumItalic.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-MediumItalic.svg#ProductSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-Medium.eot');
  src: url('../assets/fonts/ProductSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-Medium.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-Medium.woff') format('woff'),
  url('../assets/fonts/ProductSans-Medium.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-Medium.svg#ProductSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-Thin.eot');
  src: url('../assets/fonts/ProductSans-Thin.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-Thin.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-Thin.woff') format('woff'),
  url('../assets/fonts/ProductSans-Thin.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-Thin.svg#ProductSans-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-ThinItalic.eot');
  src: url('../assets/fonts/ProductSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-ThinItalic.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-ThinItalic.woff') format('woff'),
  url('../assets/fonts/ProductSans-ThinItalic.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-ThinItalic.svg#ProductSans-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-Black.eot');
  src: url('../assets/fonts/ProductSans-Black.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-Black.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-Black.woff') format('woff'),
  url('../assets/fonts/ProductSans-Black.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-Black.svg#ProductSans-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-Bold.eot');
  src: url('../assets/fonts/ProductSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-Bold.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-Bold.woff') format('woff'),
  url('../assets/fonts/ProductSans-Bold.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-Bold.svg#ProductSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-BoldItalic.eot');
  src: url('../assets/fonts/ProductSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-BoldItalic.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-BoldItalic.woff') format('woff'),
  url('../assets/fonts/ProductSans-BoldItalic.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-BoldItalic.svg#ProductSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-BlackItalic.eot');
  src: url('../assets/fonts/ProductSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-BlackItalic.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-BlackItalic.woff') format('woff'),
  url('../assets/fonts/ProductSans-BlackItalic.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-BlackItalic.svg#ProductSans-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-Italic.eot');
  src: url('../assets/fonts/ProductSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-Italic.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-Italic.woff') format('woff'),
  url('../assets/fonts/ProductSans-Italic.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-Italic.svg#ProductSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-Light.eot');
  src: url('../assets/fonts/ProductSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-Light.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-Light.woff') format('woff'),
  url('../assets/fonts/ProductSans-Light.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-Light.svg#ProductSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'GoogleSans';
  src: url('../assets/fonts/ProductSans-LightItalic.eot');
  src: url('../assets/fonts/ProductSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/ProductSans-LightItalic.woff2') format('woff2'),
  url('../assets/fonts/ProductSans-LightItalic.woff') format('woff'),
  url('../assets/fonts/ProductSans-LightItalic.ttf') format('truetype'),
  url('../assets/fonts/ProductSans-LightItalic.svg#ProductSans-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}



$h4-header-font-size: calc(var(--fontSize) + 0.5rem);
$h4-header-font-weight: normal;
$header-line-height: 1.875rem;
$font-medium: var(--fontFamily), sans-serif;
$font-regular: var(--fontFamily), sans-serif;
$font-bold: var(--fontFamily), sans-serif;
$font-table:  calc(var(--fontSize) - 0.125rem);
$title-font-size: 1.125rem;
$form-sub-header:calc(var(--fontSize) + 0.25rem);
$font-extra-small: 0.5rem;
$font-small:calc(var(--fontSize) - 0.125rem);
