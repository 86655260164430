/* You can add global styles to this file, and also import other style files */
@import 'themes';
@import '~@nebular/theme/styles/globals';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');
@include nb-install() {
  @include nb-theme-global();
};

@import "styles/color-palatte";
@import "styles/helper";
@import "styles/typography";

/*Common Classes*/
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 0.313rem;
}

::-webkit-scrollbar {
  width: 0.313rem;
  height: 0.313rem;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.313rem;
  background-color: #d5d5d5;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body {
  font-family: var(--fontFamily), sans-serif !important;
  box-sizing: border-box;
}
.nb-theme-corporate nb-calendar-picker .day-cell.selected {
  background-color: var(--secondaryColor) !important;
  border-radius: $border-radius;
  box-shadow: 0px 8px 16px #00000013;
}
.nb-theme-corporate nb-calendar-picker .day-cell:not(.disabled):not(.empty).selected .cell-content {
  background-color: var(--secondaryColor) !important;
}
.widget-main-content {
  height: 100vh;
  background-color: white;
}
.header-div {
  position: relative;
  background-color: var(--primaryColor);
  height: 30vh;
  clip-path: ellipse(70% 80% at 50% 0%);
}

.bottom-main-div {
  position: relative;
  height: 100vh;
  //background: url("./assets/images/bg.svg");
  background: url("assets/images/bg.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100vw auto;
  background-color: var(--secondaryColor);
}
.preview-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10%;
  margin-left: 10%;
  background-color: #ffffff;
  min-height: 75%;
  width: 80%;
  border-radius: 20px;
  padding-bottom: 30px;
  z-index: 2;
}
.logo-main {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.logo-image-div {
  width: 170px;
  height: 60px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.main-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.venue-name {
  font-size: $font-small;
  color: $font-common-light;
  font-weight: 500;
}
.top-details {
  background-color: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
}

.date-div-main {
  color: $font-header-dark;
  font-weight: 700;
  width: 100%;
  text-align: center;
}
.common-chips {
  padding: 10px 10px;
  justify-content: center;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background-color: #ffffff;
  color: $font-header-dark;
  cursor: pointer;
}
.common-chips.selected {
  background-color: var(--secondaryColor);
  color: #ffffff;
}
.common-title {
  font-size: $title-font-size;
  color: $font-header-dark;
  font-weight: 500;
}
.nb-theme-corporate nb-toast .title {
  display: none !important;
}
.form-label {
  font-weight: 500;
  font-size: 1rem;
  color: $font-common-dark;
}
.nb-theme-corporate .phone-input .nb-form-field-prefix-medium,
.nb-theme-corporate .phone-input .nb-form-field-prefix-large {
  width: 7rem;
}
.nb-theme-corporate .phone-input nb-select.appearance-outline.size-medium .select-button,
{
  padding: 0.4375rem 0.4375rem;
}
.nb-theme-corporate .phone-input nb-select.appearance-outline.size-large .select-button {
  padding: 0.6875rem 1rem;
}
.nb-theme-corporate .phone-input .nb-form-field-control-with-prefix [nbInput].size-medium,
.nb-theme-corporate .phone-input .nb-form-field-control-with-prefix [nbInput].size-large {
  padding-left: 3.5rem;
}
.nb-theme-corporate nb-option-list.size-medium nb-option,
.nb-theme-corporate nb-option-list.size-large nb-option {
  font-weight: normal !important;
}

.nb-theme-corporate nb-select .select-button {
  min-width: 0;
}

.custom-option-list {
  width: 300px !important;
  font-weight: normal;
}

@media (max-width: 768px) {
  .preview-content {
    width: 80%;
    left: 2%;
    top: 0%;
  }
  .logo-main {
    height: 140px;
  }
  .nb-theme-corporate nb-layout .layout .layout-container .content .columns nb-layout-column {
    padding: 0;
  }
  .header-div {
    height: 20vh;
  }
  .p-50{
    padding: 25px;
  }
}

.card-number {
  background-color: white;
  border-color: $border-color;
  height: 38px;

  input {

  }
}
.nb-theme-corporate nb-spinner.status-primary {
  background-color: #ffffff !important;
}
.nb-theme-corporate nb-card-body {
  overflow: visible;
}
.overflow-y{
  overflow-y: auto;
}
.overflow-x{
  overflow-x: hidden;
}

@media screen  and (max-width: 1024px){
  .preview-content {
    margin-top: -20%;
  }
}

@media screen  and (max-width: 600px){
  .preview-content{
    width: 90%;
    margin-top: -13%;
    height: 95vh;
    padding-bottom: 15px;
    margin-left: 4%;
  }
  .header-div{
    height: 10vh;
  }
  .logo-main{
    height: 110px;
  }
}

