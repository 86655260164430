// Theme color
:root {
  --primaryColor: #34a853;
  --secondaryColor: #fbbc05;
}

$primary-light: #7fc3a8;
$secondary-color: #fbbc05;
$secondary-light: #fae692;

//Alerts color
$danger-color: #fe5050;
$info-color: #3eaeff;
$warning-color: #ff9700;

// input

$border-color: #e7ecf2;

// font colors

$font-common-dark: #66737f;
$font-common-light: #b8c1cc;
$font-header-dark: #013243;

// gray

$table-row-gray: #f2f5f8;
$background-color: #f2f5f8;

$border-radius: 0.313rem;

$footer-color: #769099;
